import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Music from "./pages/Music";
import Poems from "./pages/Poems";
import ComeSeeMe from "./pages/ComeSeeMe";
import HireMe from "./pages/HireMe";
import Pictures from "./pages/Pictures";

class Main extends Component {
	render() {
		return (
			<main className="main">
				<div className="container">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/home" element={<Home />} />
						<Route path="/music" element={<Music />} />
						<Route path="/poems" element={<Poems />} />
						<Route path="/comeSeeMe" element={<ComeSeeMe />} />
						<Route path="/hireMe" element={<HireMe />} />
						<Route path="/pictures" element={<Pictures />} />
					</Routes>
				</div>
			</main>
		);
	}
}

export default Main;
