import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
	en: {
		translation: {
			header: {
				nav: {
					home: "Home",
					music: "Music",
					poems: "Poems",
					comeSeeMe: "Come See Me",
					hireMe: "Hire Me",
					pictures: "Pictures",
				},
			},
			home: {
				fold: {
					subtitle1: "Singer",
					subtitle2: "Songwriter",
					subtitle3: "Poet",
				},
				body: {
					title1: "Darius_, the artist",
					text1: `Formerly known as Legendarius, Darius Van de Velde has undergone a profound transformation, emerging as
						the artist Darius_. This reinvention marks more than just a change in name - it signifies a deeper
						exploration of his creative identity and a shift toward a new, more authentic musical style. The
						underscore in his name symbolizes that the story is not done, there is more to be said. With this new
						persona, Darius_ has developed a sound that blends elements from Ballad and Pop, creating music that
						deeply resonates with listeners. His goal is to touch hearts and evoke new emotions, with each song
						reflecting his personal experiences and the stories he has encountered. Darius_ places a strong emphasis
						on lyrics, using them to craft stories that listeners can interpret in their own way. Drawing
						inspiration from personal experiences, stories and poetry, his music serves as a mirror for the
						listener's emotions, offering both comfort and insight.`,
					title2: "Darius_, the poet",
					text2: `In addition to his music, Darius_ expresses himself through poetry under the alias Poetrius_. This
						aspect of his artistry allows him to explore the power of language to convey deep emotions and ideas,
						often touching on themes like love the act of writing peorty itself. For Darius_, poetry is a means of
						self-discovery. His poetry is both personal and relatable, offering readers a raw, intimate look into
						his inner world. Together, Darius_' music and poetry create a full artistic expression, with his music
						providing immediate emotional impact and his poetry offering a quieter, reflective space. Both mediums
						are essential to his identity, allowing him to express the full range of his emotions and thoughts.`,
				},
			},
			music: {
				pageTitle: "Music",
				buttonText: "Listen",
				popupText: "Listen to",
				highlights: {
					featured: "Featured",
					mostPlayed: "Most Played",
					mostRecent: "Most Recent",
				},
			},
			comeSeeMe: {
				pageTitle: "Come see me",
				pastEvents: "Past Events",
				futureEvents: "Future Events",
				noEventMessage: "No events planned at the moment.",
				learnMoreButton: "Learn more",
			},
			hireMe: {
				pageTitle: "Hire Me",
				text1: "Message me on any of my socials or via e-mail. You can find all of them at the bottom of my website.",
				text2: "An in-website messaging service is currently under development.",
			},
			pictures: {
				pageTitle: "Pictures",
			},
		},
	},
	nl: {
		translation: {
			header: {
				nav: {
					home: "Home",
					music: "Muziek",
					poems: "Poëzie",
					comeSeeMe: "Mijn optredens",
					hireMe: "Contact",
					pictures: "Foto's",
				},
			},
			home: {
				fold: {
					subtitle1: "Zanger",
					subtitle2: "Liedjesschrijver",
					subtitle3: "Dichter",
				},
				body: {
					title1: "Darius_, de artiest",
					text1: `Darius Van de Velde begon zijn carrière als Legendarius, maar heeft sindsdien een diepgaande transformatie ondergaan en is
						tevoorschijn gekomen als de artiest Darius_. Deze heruitvinding markeert meer dan alleen een verandering
						van naam - het duidt op een diepere verkenning van zijn creatieve identiteit en een verschuiving naar een
						nieuwe, authentiekere muziekstijl. Het liggende streepje in zijn naam symboliseert dat het verhaal nog niet
						af is, er valt nog meer te zeggen. Met dit nieuwe persona heeft Darius_ een geluid ontwikkeld dat
						elementen combineert uit Ballade en Pop, waardoor muziek ontstaat die diep resoneert bij luisteraars. Zijn
						doel is om harten te raken en nieuwe emoties op te roepen, waarbij elk nummer zijn persoonlijke ervaringen
						en de verhalen die hij is tegengekomen weerspiegelt. Darius_ legt een sterke nadruk op teksten, waarbij hij
						ze gebruikt om verhalen te creëren die luisteraars op hun eigen manier kunnen interpreteren. Geïnspireerd
						door persoonlijke ervaringen, verhalen en poëzie, dient zijn muziek als een spiegel voor de emoties van de
						luisteraar, waarbij zowel troost als inzicht wordt geboden.`,
					title2: "Darius_, de dichter",
					text2: `Naast zijn muziek drukt Darius_ zich ook uit door middel van poëzie onder het pseudoniem Poetrius_. Dit aspect van
						zijn artistieke expressie stelt hem in staat om de kracht van taal te verkennen om diepe emoties en ideeën over
						te brengen, vaak met thema's als liefde en het schrijven van poëzie zelf. Voor Darius_ is poëzie een middel
						tot zelfontdekking. Zijn poëzie is zowel persoonlijk als herkenbaar en biedt lezers een rauwe, intieme blik
						in zijn innerlijke wereld. Samen vormen Darius_' muziek en poëzie een volledig artistieke pakket, waarbij
						zijn muziek directe emotionele impact biedt en zijn poëzie een stillere, reflectieve ruimte biedt. Beide
						media zijn essentieel voor zijn identiteit en stellen hem in staat om het volledige scala van zijn emoties
						en gedachten uit te drukken.`,
				},
			},
			music: {
				pageTitle: "Muziek",
				buttonText: "Luister",
				popupText: "Luister naar",
				highlights: {
					featured: "Uitgelicht",
					mostPlayed: "Meest Beluisterd",
					mostRecent: "Recentst",
				},
			},
			comeSeeMe: {
				pageTitle: "Mijn optredens",
				pastEvents: "Voorbije evenementen",
				futureEvents: "Toekomstige evenementen",
				noEventMessage: "Geen evenementen gepland op dit moment.",
				learnMoreButton: "Lees meer",
			},
			hireMe: {
				pageTitle: "Contact",
				text1: "Stuur me een bericht via een van mijn sociale media of via e-mail. Je kunt ze allemaal onderaan mijn website vinden.",
				text2: "Een berichtenservice binnen de website is momenteel in ontwikkeling.",
			},
			pictures: {
				pageTitle: "Foto's",
			},
		},
	},
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: "nl", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
