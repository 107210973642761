import React from "react";
import { useTranslation } from "react-i18next";

const Fold = () => {
	const { t } = useTranslation();
	return (
		<div className="c-fold position-relative h-35rem">
			<div
				className="c-fold__background zindex-min1 overflow-hidden d-flex justify-content-center
				width-100vw h-35rem position-absolute start-50 top-50 translate-middle overflow-hidden"
			>
				<img
					src={`${process.env.PUBLIC_URL}/assets/img/fold_background.jpg`}
					alt="background"
					className="mnh-100 mnw-100 object-fit-cover filter-blur-8 overflow-hidden"
				/>
			</div>
			<div className="container position-absolute top-0">
				<div className="row d-flex flex-column-reverse flex-sm-row align-items-sm-center mt-5 mt-sm-6">
					<div className="col-sm">
						<div className="text-center mt-3 text-white">
							<h1>Darius_</h1>
							<div className="d-md-flex justify-content-center">
								<p className="c-profession mx-3">{t("home.fold.subtitle1")}</p>
								<p className="c-profession mx-3">{t("home.fold.subtitle2")}</p>
								<p className="c-profession mx-3">{t("home.fold.subtitle3")}</p>
							</div>
						</div>
					</div>
					<div className="col-sm d-flex justify-content-center">
						<img
							src={`${process.env.PUBLIC_URL}/assets/img/fold_image.jpg`}
							alt=""
							className="rounded-circle mh-15rem mh-sm-20rem drop-shadow"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const BodyText = () => {
	const { t } = useTranslation();
	return (
		<div className="container mt-5">
			<div className="row d-flex flex-column-reverse flex-md-row mb-5">
				<div className="col-md-4 d-flex d-md-block justify-content-center">
					<img src={`${process.env.PUBLIC_URL}/assets/img/img1.jpg`} alt="" className="drop-shadow mw-100 mh-30rem" />
				</div>
				<div className="col-md-8">
					<h2 className="text-white text-center text-md-left">{t("home.body.title1")}</h2>
					<p className="text-white mb-4">{t("home.body.text1")}</p>
				</div>
			</div>
			<div className="row d-flex flex-column flex-md-row">
				<div className="col-md-8">
					<h2 className="text-white text-center text-md-left">{t("home.body.title2")}</h2>
					<p className="text-white mb-4">{t("home.body.text2")}</p>
				</div>
				<div className="col-md-4 d-flex d-md-block justify-content-center">
					<img src={`${process.env.PUBLIC_URL}/assets/img/img2.jpg`} alt="" className="drop-shadow mw-100 mh-30rem" />
				</div>
			</div>
		</div>
	);
};

const Home = () => {
	return (
		<>
			<Fold />
			<BodyText />
		</>
	);
};

export default Home;
