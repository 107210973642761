import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const picturesGrid = (nPictures, picturesFolder) => {
	const pictures = [];
	for (let i = nPictures - 1; i >= 0; i--) {
		const picture = `${picturesFolder}/pictures_${i}.jpg`;
		pictures.push(
			<div className={`col-xxl-2 col-lg-3 col-sm-4 col-xs-6 mb-3`} key={i}>
				<img
					src={`${process.env.PUBLIC_URL}/${picture}`}
					className="img-fluid object-fit-cover h-100 cursor-pointer"
					//onClick={() => openOverlay(picture)}
					alt=""
				/>
			</div>
		);
	}
	return pictures;
};

const Pictures = () => {
	const [nPictures, setNPictures] = useState(0);
	const [picturesFolder, setPicturesFolder] = useState("");
	const { t } = useTranslation();

	useEffect(() => {
		fetch(`${process.env.PUBLIC_URL}/data/pictures.json`)
			.then(res => res.json())
			.then(data => {
				setNPictures(data.nPictures);
				setPicturesFolder(data.picturesFolder);
			})
			.catch(error => console.error("Error", error));
	}, []);

	return (
		<div>
			<h1 className="color-white mt-3">{t("pictures.pageTitle")}</h1>
			<div className="row">{picturesGrid(nPictures, picturesFolder)}</div>
		</div>
	);
};

export default Pictures;
