import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function NavElement({ to, children, ...props }) {
	const resolvedPath = useResolvedPath(to);
	const isActive = useMatch({ path: resolvedPath.pathname, end: true });

	return (
		<Nav.Link as={Link} to={to} className={`text-white c-nav__link ${isActive ? "active" : ""}`} {...props}>
			{children}
		</Nav.Link>
	);
}

const Navigation = () => {
	const { t } = useTranslation();
	return (
		<Nav className="ms-aut me-3 d-flex gap-2">
			<NavElement to="/home">{t("header.nav.home")}</NavElement>
			<NavElement to="/music">{t("header.nav.music")}</NavElement>
			{/* <NavElement to="/poems">{t("header.nav.poems")}</NavElement> */}
			<NavElement to="/comeSeeMe">{t("header.nav.comeSeeMe")}</NavElement>
			{/* <NavElement to="/hireMe">{t("header.nav.hireMe")}</NavElement> */}
			<NavElement to="/pictures">{t("header.nav.pictures")}</NavElement>
		</Nav>
	);
};

export default Navigation;
