import { React } from "react";
import { useTranslation } from "react-i18next";

const HireMe = () => {
	const { t } = useTranslation();
	return (
		<>
			<h1 className="text-white mt-3">{t("hireMe.pageTitle")}</h1>
			<p className="text-white mt-2">{t("hireMe.text1")}</p>
			<p className="text-white">{t("hireMe.text2")}</p>
		</>
	);
};

export default HireMe;
