import React, { Component } from "react";
import { FaFacebookSquare, FaInstagram, FaTiktok, FaSpotify } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

function Social({ url, children, ...props }) {
	return (
		<a
			href={url}
			className={`color-white d-flex gap-1 align-items-center ${url !== "#" ? "" : "text-secondary"}`}
			target={url !== "#" ? "_blank" : ""}
			rel="noreferrer"
			{...props}
		>
			{children}
		</a>
	);
}

class Footer extends Component {
	state = {
		socials: {},
	};

	componentDidMount() {
		fetch(`${process.env.PUBLIC_URL}/data/socials.json`)
			.then(response => response.json())
			.then(data => {
				this.setState({ socials: data });
			})
			.catch(error => {
				console.error("Error fetching socials data:", error);
			});
	}
	render() {
		return (
			<footer className="footer pt-2 mt-4">
				<div className="container">
					<div className="row">
						{Object.entries(this.state.socials).map(([key, value]) => (
							<div className="col-lg-2 col-md-4 col-sm-6" key={key}>
								<Social url={value}>
									{key === "facebook" && <FaFacebookSquare />}
									{key === "facebook" && "Facebook"}
									{key === "instaMusic" && <FaInstagram />}
									{key === "instaMusic" && "Instagram Music"}
									{key === "instaPoetry" && <FaInstagram />}
									{key === "instaPoetry" && "Instagram Poetry"}
									{key === "tiktok" && <FaTiktok />}
									{key === "tiktok" && "TikTok"}
									{key === "spotify" && <FaSpotify />}
									{key === "spotify" && "Spotify"}
									{key === "email" && <FiMail />}
									{key === "email" && "E-Mail"}
								</Social>
							</div>
						))}
					</div>
				</div>
				<p className="text-secondary text-end mb-0 font-monospace">© 2024 Jentl Antheunis</p>
			</footer>
		);
	}
}

export default Footer;
