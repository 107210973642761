import React, { Component } from "react";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";

import "bootstrap/dist/css/bootstrap.min.css";

class App extends Component {
	render() {
		return (
			<>
				<div className="main-content">
					<Header />
					<Main />
				</div>
				<Footer />
			</>
		);
	}
}

export default App;
