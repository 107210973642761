import { React, Component } from "react";
import Navigation from "./Navigation";
import { Navbar, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { changeLanguage } from "i18next";

class Header extends Component {
	render() {
		return (
			<header className="c-header bg-dark">
				<Navbar expand="md">
					<Container>
						<Navbar.Brand className="c-header__title">
							<Link to="/" className="c-header__link text-white">
								Darius_
							</Link>
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="basic-navbar-nav">
							<RxHamburgerMenu stroke="white" fill="white" className="color-white" />
						</Navbar.Toggle>
						<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
							<Navigation />
							<Form.Select
								size="sm"
								className="c-header__select mt-3 mt-md-0 ms-md-3 bg-dark text-white border-dark"
								onChange={e => changeLanguage(e.target.value)}
							>
								<option value="nl">Nederlands</option>
								<option value="en">English</option>
							</Form.Select>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</header>
		);
	}
}

export default Header;
