import { React, useState, useEffect } from "react";
import { Card, Button, Modal } from "react-bootstrap";
import { CiPlay1 } from "react-icons/ci";
import { FaSpotify, FaDeezer, FaYoutube } from "react-icons/fa";
import { SiYoutubemusic, SiApplemusic, SiTidal, SiAmazonmusic } from "react-icons/si";
import { useTranslation } from "react-i18next";

const Highlight = ({ type, img, links, children }) => {
	const { t } = useTranslation();
	return (
		<div className="col">
			<Card className="mb-2">
				<Card.Header className="text-center">{t(`music.highlights.${type}`)}</Card.Header>
				<Card.Img src={`${process.env.PUBLIC_URL}/${img}`} />
				<Card.Title className="text-center" style={{ margin: ".5rem" }}>
					{children}
				</Card.Title>
				<LinkPopup links={links} title={children} />
			</Card>
		</div>
	);
};

const ListItem = ({ img, duration, links, children }) => {
	return (
		<div className="container">
			<div className="row mb-2 bg-dark p-1">
				<div className="d-xs-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<div className="me-3">
							<img src={`${process.env.PUBLIC_URL}/${img}`} height={64} alt="Cover art" />
						</div>
						<div className="d-sm-flex">
							<div className="me-3 text-white">{children}</div>
							<div className="text-secondary">{duration}</div>
						</div>
					</div>
					<div className="mt-2 d-flex justify-content-center">
						<LinkPopup links={links} title={children} />
					</div>
				</div>
			</div>
		</div>
	);
};

const LinkPopup = ({ title, links }) => {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const icons = {
		spotify: {
			icon: <FaSpotify />,
			name: "Spotify",
		},
		deezer: {
			icon: <FaDeezer />,
			name: "Deezer",
		},
		youtube: {
			icon: <FaYoutube />,
			name: "YouTube",
		},
		youtubeMusic: {
			icon: <SiYoutubemusic />,
			name: "YouTube Music",
		},
		appleMusic: {
			icon: <SiApplemusic />,
			name: "Apple Music",
		},
		tidal: {
			icon: <SiTidal />,
			name: "Tidal",
		},
		amazonMusic: {
			icon: <SiAmazonmusic />,
			name: "Amazon Music",
		},
	};

	return (
		<>
			<Button className="d-flex justify-content-center align-items-center gx-3" onClick={handleShow}>
				<CiPlay1 className="me-1" />
				{t("music.buttonText")}
			</Button>
			<Modal show={show} onHide={handleClose} className="">
				<Modal.Header>
					<Modal.Title>
						{t("music.popupText")} {title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ul>
						{Object.entries(links).map(([key, value]) => {
							if (value === "" || value === null || value === "#") {
								return null;
							} else {
								return (
									<li className="liststyle-none mb-4 mb-md-2" key={key}>
										<a href={value} target="_blank" rel="noreferrer">
											{icons[key].icon} {icons[key].name}
										</a>
									</li>
								);
							}
						})}
					</ul>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

const Music = () => {
	const [highlights, setHighlights] = useState({});
	const [list, setList] = useState({});
	const [displayHighlights, setDisplayHighlights] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		fetch(`${process.env.PUBLIC_URL}/data/music.json`)
			.then(res => res.json())
			.then(data => {
				setHighlights(data.highlights);
				setList(data.list);
				setDisplayHighlights(data.displayHighlights);
			})
			.catch(error => console.error("Error", error));
	}, []);

	return (
		<>
			<h1 className="color-white mt-3">{t("music.pageTitle")}</h1>
			{displayHighlights && (
				<div className="row">
					{Object.values(highlights).map(highlight => (
						<div className="col-xs-6 col-sm-4 col-lg-3 col-xl-2 mb-2" key={highlight.id}>
							<Highlight type={highlight.type} img={list[highlight.id].img} links={list[highlight.id].links}>
								{list[highlight.id].title}
							</Highlight>
						</div>
					))}
				</div>
			)}
			{Object.values(list).map(item => {
				if (item.released === true) {
					return (
						<ListItem img={item.img} duration={item.duration} key={item.id} links={item.links}>
							{item.title}
						</ListItem>
					);
				} else {
					return null;
				}
			})}
		</>
	);
};

export default Music;
